import React from 'react';
import './shared.scss';
import background from "./img/bg-cover-3.jpg"

const hackLogoDisplay = () => {
  // Little hack to force display of parallaxed logo - especially on Firefox
  let sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
  sleep(1).then(() => {
    document.getElementById("root").scrollTo(0, 1);
    document.getElementById("root").scrollTo(0, 0);
  })
}
const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
const innerHTML = <>
  <h2 className="homepage-sectionTitle">We build products.</h2>
  <p className="homepage-sectionContent">
  Our team consists of former tech founders who deeply understand that a product only exists within a given market and business reality. We know that building a product is a constant game of compromises between value, resources and time.
  <br/><br/>
  As such, we are not here to deliver fancy lines of code written in a vacuum.
  <br/><br/>
  We are here to build the tech and features that make sense for your product within your market and your constraints so that you may achieve your business objectives. No more. No less.
  </p>
</>

class App extends React.Component {
  componentDidMount() {
    hackLogoDisplay();
  }

  render() {
    return (
      <>
        <div className="homepage-sectionWrapper" style={{height: "110vh"}}></div>
        <p className="shared-parallaxed homepage-parallaxedContent homepage-letter homepage-c1">C</p>
        <p className="shared-parallaxed homepage-parallaxedContent homepage-letter homepage-r">r</p>
        <p className="shared-parallaxed homepage-parallaxedContent homepage-letter homepage-a1">a</p>
        <p className="shared-parallaxed homepage-parallaxedContent homepage-letter homepage-f">f</p>
        <p className="shared-parallaxed homepage-parallaxedContent homepage-letter homepage-t">t</p>
        <p className="shared-parallaxed homepage-parallaxedContent homepage-letter homepage-e">e</p>
        <p className="shared-parallaxed homepage-parallaxedContent homepage-letter homepage-d">d</p>
        <p className="shared-parallaxed homepage-parallaxedContent homepage-letter homepage-h"><b>H</b></p>
        <p className="shared-parallaxed homepage-parallaxedContent homepage-letter homepage-a2"><b>a</b></p>
        <p className="shared-parallaxed homepage-parallaxedContent homepage-letter homepage-c2"><b>c</b></p>
        <p className="shared-parallaxed homepage-parallaxedContent homepage-letter homepage-k"><b>k</b></p>
        <div className="homepage-sectionWrapper"></div>
        <ParallaxedComponent id={"intro"} />
      </>
    );
  }
}

class ParallaxedComponent extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div id={this.props.id} className="shared-parallaxed homepage-parallaxedWrapper">
        <div className="homepage-parallaxedInnerWrapper">
          <div className="homepage-parallaxedContentWrapper">
            {this.props.content}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
